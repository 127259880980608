<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">DOWNLOADS/UPLOADS</span>
      COURSES
    </PageHeader>

    <v-row align="center" justify="center">
      <v-col>
        <div class="red--text text-center pa-4">
          <span class="">CAUTION:</span>
          ALL DOWNLOADED SCRIPTS SHOULD BE SAVED BEFORE UPLOADING
        </div>
      </v-col>
    </v-row>

    <v-card tile elevation="1">
      <v-card-text>
        <template v-if="loadResponse && getters_abilities.length > 0">
          <v-row justify="center" align="center" v-if="basket.length > 0">
            <v-col cols="12" class="px-0">
              <v-slide-y-transition
                class="py-0"
                color="primary"
                group
                tag="v-list"
              >
                <template v-for="(item, index) in basket">
                  <v-divider
                    v-if="index !== 0"
                    :key="`${index}-divider`"
                  ></v-divider>

                  <v-list-item :key="`${index}-item`">
                    <v-row
                      justify="center"
                      align="center"
                      :key="`course ${index}`"
                      class="px-2 text-uppercase"
                    >
                      <v-col cols="12" lg="3" class="pa-0">
                        {{ item.course.coursecode_coursename }}
                      </v-col>
                      <v-col class="pa-0" cols="12" lg="3">
                        {{ item.program.program_name_certificate }}
                      </v-col>
                      <v-col class="pa-0" cols="12" lg="1">
                        {{ item.calendar.academic_calendar }}
                      </v-col>
                      <v-col class="pa-0" cols="12" lg="2">
                        {{ item.semester.semester }}
                        {{ `L${item.level.level}` }}
                      </v-col>
                      <v-col class="pa-0" cols="12" lg="2">
                        {{ item.intake.intake }}
                        {{ item.campus.campus_name }}
                      </v-col>
                      <v-col cols="12" lg="1" class="text-right pa-0">
                        <div class="d-inline">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                :disabled="
                                  !getters_abilities.includes(
                                    'download_upload_access'
                                  )
                                "
                                icon
                                color="success"
                                class="mx-1"
                                @click="getscriptBtn(item)"
                              >
                                <v-icon large>
                                  mdi-cloud-download-outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Click To Download Script</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                :disabled="
                                  !getters_abilities.includes(
                                    'download_upload_access'
                                  )
                                "
                                icon
                                color="warning"
                                class="mx-1"
                                @click="showUploadScreen(item)"
                              >
                                <v-icon large>
                                  mdi-arrow-right-bold-circle-outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Click To Upload Script</span>
                          </v-tooltip>
                        </div>
                      </v-col>
                    </v-row>
                  </v-list-item>
                </template>
              </v-slide-y-transition>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col> Not courses available </v-col>
          </v-row>
        </template>
        <div v-else style="min-height: 20px">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="text-subtitle-1 text-center" cols="12">
              Getting your files
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                color="primary accent-4"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>

    <v-navigation-drawer
      fixed
      v-model="showUpload"
      :permanent="showUpload"
      right
      width="75%"
      class="shadow"
      clipped
      elevation-19
    >
      <div class="mt-15 d-flex justify-space-between">
        <v-spacer></v-spacer>
        <v-btn class="ma-2" @click="showUpload = false" icon>
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </div>
      <upload-template v-if="showUpload"></upload-template>
    </v-navigation-drawer>

    <v-overlay absolute :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-dialog
      fullscreen
      v-model="dialog"
      hide-overlay
      transition="dialog-top-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn dark icon @click="dialog = false">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
          <v-toolbar-title>RESULTS</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <p class="ma-3">
            <strong>
              Click on the icon on the top left of the screen when closing...
            </strong>
          </p>
          <v-row justify="end">
            <v-col cols="3">
              <v-spacer></v-spacer>
              <v-text-field
                append-icon="mdi-magnify"
                label="Search"
                v-model="search"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                :items="resultBasket"
                :search="search"
                hide-default-footer
                class="elevation-1"
                fixed-header
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      centered
      absolute
      transition="slide-y-transition"
      multi-line
      color="error"
      v-model="filetypeResponse"
    >
      <v-row>
        <v-col cols="2">
          <v-icon x-large> mdi-close-circle </v-icon>
        </v-col>
        <v-col>
          <div>Error</div>
          <div>{{ importResponse }}</div>
        </v-col>
      </v-row>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="filetypeResponse = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <Response v-if="actionResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>
<script>
  import { getCurrentInstance, provide, reactive, ref, toRefs } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import PageHeader from "@/components/slots/PageHeader";
  import Response from "@/components/ActionResponse/Response";
  import UploadTemplate from "@/components/Uploads/UploadTemplate.vue";
  // import Api from "@/apis/Api";

  export default {
    components: { PageHeader, Response, UploadTemplate },
    setup() {
      const vm = getCurrentInstance();
      const { getCourseLecturerCurrentCalendar, getScripts, signOut } =
        useActions(["getCourseLecturerCurrentCalendar", "getScripts", "signOut"]);

      let basket = ref([]);
      let dialog = ref(false);
      let overlay = ref(false);
      let filetypeResponse = ref(false);
      let importResponse = ref("");
      let resultBasket = ref([]);
      let search = ref("");
      let loadResponse = ref(false);
      const showUpload = ref(false);
      const scriptFileUpload = ref({});

      let resultHeaders = reactive({
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        actionResponse: false,
        color: "",
        headers: [
          { text: "INDEX NUMBER", value: "index_number" },
          { text: "NAME OF STUDENT", value: "fullname" },
          { text: "MID SEMESTER SCORE", value: "midsem", align: "center" },
          {
            text: "END OF SEMESTER SCORE",
            value: "exam",
            align: "center",
          },
          { text: "TOTAL SCORE", value: "total", align: "center" },
          { text: "GRADE", value: "grade", align: "center" },
          //{ text: "SUBMITTED", value: "remarks", align: "center" },
        ],
      });

      const { msgHeader, msgBody, msgIcon, actionResponse, color } =
        toRefs(resultHeaders);

      const { getters_courselecturercurrcalendar, user, getters_abilities } =
        useGetters([
          "getters_courselecturercurrcalendar",
          "user",
          "getters_abilities",
        ]);

      Promise.all([
        getCourseLecturerCurrentCalendar(user.value.id).then(() => {
          basket.value = getters_courselecturercurrcalendar.value;
          loadResponse.value = true;
        }),
      ]).catch((e) => {
        overlay.value = false;
        actionResponse.value = true;
        msgHeader.value = "Error";
        msgIcon.value = "mdi-close-circle";
        color.value = "error";
        switch (e.response.status) {
          case 403:
            msgBody.value = e.response.data.message;
            break;
          case 423:
            msgBody.value = e.response.data.message;
            signOut().then(() => {
              vm.proxy.$router.replace({
                name: "Login",
              });
            });
            break;
          default:
            msgBody.value = "Try Again Later Or Call The System Administrator";
            break;
        }
      });

      const getscriptBtn = (item) => {
        loadResponse.value = false;
        actionResponse.value = false;
        getScripts(item)
          .then(() => {
            loadResponse.value = true;
          })
          .catch((e) => {
            overlay.value = false;
            actionResponse.value = true;
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";

            switch (e.response.status) {
              case 403:
                msgBody.value = e.response.data.message;
                break;
              case 423:
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
                break;
              default:
                msgBody.value =
                  "Try Again Later Or Call The System Administrator";
                break;
            }
          });
      };

      const showUploadScreen = (item) => {
        scriptFileUpload.value = item;
        showUpload.value = true;
      };

      provide("scriptFileUpload", {
        script: scriptFileUpload,
        scriptType: "main",
      });

      provide("color", color);

      return {
        getters_abilities,
        basket,
        dialog,
        getscriptBtn,
        user,
        overlay,
        filetypeResponse,
        importResponse,
        ...toRefs(resultHeaders),
        resultBasket,
        search,
        loadResponse,
        showUploadScreen,
        showUpload,
      };
    },
  };
</script>
<style>
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.03);
  }
  .v-data-table-header th {
    white-space: nowrap !important;
  }
</style>
